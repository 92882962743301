a {
    text-decoration: none;
    color:currentColor !important;
}

a:hover {
    text-decoration: underline;
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.flex-1 {
    flex: 1 1 0%;
}